import * as React from "react";
import ReactTooltip from "react-tooltip";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import {getPageTitle} from "../components/Helpers/Meta";
import {getBackgroundColorFromName} from "../components/Helpers/Color";
import Breadcrumb from "../components/Breadcrumb";
import {Helmet} from "react-helmet";
import {off, on, trigger} from "../components/Events";
import addToMailchimp from 'gatsby-plugin-mailchimp'
import {useEffect, useRef, useState} from "react";
import {gsap} from "gsap";
import Fade from "../transitions/Fade";

const CTAContactFormTemplate = () => {
    const formRef = useRef();
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    const [isSendDisabled, setIsSendDisabled] = useState(false);

    const onSubmit = async (e) => {
        e.preventDefault();

        setIsSendDisabled(true);
        const nameParts = name.trim().split(/\s/);

        try {
            const result = await addToMailchimp(email, {
                FNAME: nameParts.shift(),
                LNAME: nameParts.join(' '),
                PHONE: phoneNumber.trim(),
                COMPANY: companyName.trim(),
                MESSAGE: message.trim(),
                'group[346373][1]': 1
            }, 'https://weekend.us8.list-manage.com/subscribe/post?u=76e35e5c7991530720d793674&amp;id=50acd9a47e');
            if (result && result.result === 'success') {
                gsap.timeline()
                    .to('.c-cta-contact', {
                        opacity: 0,
                        display: 'none',
                        duration: 0.5
                    })
                    .to('.c-cta-contact--success', {
                        opacity: 1,
                        display: 'flex',
                        duration: 0.6
                    })
            }
        } catch (e) {
            console.log('e', e);
        }

        setIsSendDisabled(false);
    };


    const hidePage = () => {
        Fade.animate([
            { target: '.o-section', type: Fade.TYPE_OPACITY },
            { target: '.c-logo', type: Fade.TYPE_OPACITY },
            { target: '.c-breadcrumbs', type: Fade.TYPE_OPACITY },
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])

    return (
        <>
            <ReactTooltip />
            <Navigation white={true}/>
            <Logo breakLapPlus={true} state={Logo.WHITE} />

            <Helmet>
                <title>{getPageTitle({title: 'Contact'})}</title>
                <style>
                    {`body { background-color: ${getBackgroundColorFromName('blauw')} }`}
                </style>

            </Helmet>
            <Breadcrumb text={"Contact"} white={true} />
            <div className={"o-layout--full-page c-cta-contact--success"}>
                <img src={"/images/cta_mail.gif"} className={"c-cta-contact--success-img"} />
            </div>

            <div className={"o-section c-cta-contact u-mob--bottom-margin__default o-layout o-layout--full-page u-columns-24"}>
                <div className={"u-colspan-1 u-colspan-3@desk u-colspan-4@wall u-colspan-5@wide"} />
                <div className={"u-colspan-22 u-colspan-18@desk u-colspan-16@wall u-colspan-14@wide"}>
                    <div className={"c-cta-contact--title"}>
                        <h1>Tijd voor Weekend?</h1>
                    </div>

                    <div className={"o-layout"}>
                        <div className={"c-cta-contact--content u-colspan-12 u-colspan-8@desk u-colspan-6@xwide"}>
                            <div>
                            <p>Meer weten over onze diensten, kennis en kunde óf gewoon vrijblijvend kennismaken? Laat je gegevens achter en we nemen snel contact met je op.
                                Liever kort op de bal spelen? Bel <a href={"tel:+31765410404"} target={"_blank"} rel={"nofollow"}>+31 76 541 04 04</a>.</p>
                            </div>

                            <form ref={formRef} onSubmit={onSubmit}>
                            <div className={"o-layout o-layout--gap-base c-cta-contact--form"}>
                                <div className={"u-colspan-6"}>
                                    <label htmlFor={"name"} className={"c-cta-contact--label"}>Je naam</label>
                                    <input id={"name"} className={"c-cta-contact--input"}
                                           value={name}
                                           required={true}
                                           onChange={(e) => setName(e.target.value)} />
                                </div>
                                <div className={"u-colspan-6"}>
                                    <label htmlFor={"companyName"} className={"c-cta-contact--label"}>Je bedrijfsnaam</label>
                                    <input id={"companyName"} className={"c-cta-contact--input"}
                                           value={companyName}
                                           onChange={(e) => setCompanyName(e.target.value)} />
                                </div>
                                <div className={"u-colspan-6"}>
                                    <label htmlFor={"phone"} className={"c-cta-contact--label"}>Je telefoonnummer</label>
                                    <input id={"phone"} className={"c-cta-contact--input"}
                                           type={"tel"}
                                           value={phoneNumber}
                                           onChange={(e) => setPhoneNumber(e.target.value)}/>
                                </div>
                                <div className={"u-colspan-6"}>
                                    <label htmlFor={"email"} className={"c-cta-contact--label"}>Je e-mailadres</label>
                                    <input id={"email"} className={"c-cta-contact--input"}
                                           type={"email"}
                                           value={email}
                                           required={true}
                                           onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                <div className={"u-colspan-12 c-cta-contact--message-label"}>
                                    Optioneel kun je ons een korte toelichting geven waarover je het wilt hebben, dan zorgen wij dat we al tijdens onze kennismaking met scherp schieten.
                                </div>
                                <div className={"u-colspan-12"}>
                                    <label htmlFor={"message"} className={"c-cta-contact--label"}>Je bericht</label>
                                    <textarea id={"message"} className={"c-cta-contact--input c-cta-contact--message"} rows={15}
                                              onChange={(e) => setMessage(e.target.value)} value={message} />
                                </div>
                                <div className={"u-colspan-12"}>
                                    <button className={"c-button c-button--yellow c-cta-contact--button"}
                                            disabled={isSendDisabled}
                                         onMouseEnter={() => {
                                             trigger('cursor:click');
                                         }}
                                         onMouseOut={() => {
                                             trigger('cursor:unclick');
                                         }}
                                         onClick={() => {
                                             trigger('cursor:unclick');
                                         }} type={"submit"}>VERSTUREN</button>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className={"u-colspan-1 u-colspan-3@desk u-colspan-4@wall u-colspan-5@wide"} />
            </div>
        </>
    )
};

export default CTAContactFormTemplate;
